import React from "react";
import CopyWithPhoto from "./CopyWithPhoto";
import ContentfulRichText from "./ContentfulRichText";
import { extractFlourishColor } from "../utils/contentful";

const ContentfulCopyWithPhoto = ({
  contentfulData: {
    heading,
    headingStrapline,
    bodyContent = {},
    ctaLabel,
    ctaLink,
    photoPosition,
    photo: {
      photo,
      overlayPhoto,
      flourishColor
    },
  },
  ...props
}) => {

  const { json } = bodyContent;

  return (
    <CopyWithPhoto
      {...props}
      strapline={headingStrapline}
      title={heading}
      cta={ctaLabel}
      ctaHref={ctaLink}
      copy={json && <ContentfulRichText json={json} />}
      photoPosition={photoPosition ? `left` : `right`}
      photoFluid={photo && photo.localFile.childImageSharp.fluid}
      overlayFluid={overlayPhoto && overlayPhoto.localFile.childImageSharp.fluid}
      flourishColor={flourishColor && extractFlourishColor(flourishColor)}
    />
  );
};

export default ContentfulCopyWithPhoto;
