import React, { useRef, useEffect } from "react";
import { css } from "@emotion/core";
import Lightbox, { closeButtonMargin, closeButtonSizeWithMargins } from "./Lightbox";
import Video from "./Video";
import { COLORS } from "../css-vars";
import { pixelsToRem } from "../utils/css-helpers";

const VideoLightbox = ({
  src,
  visible,
  onCloseIntent,
  ...props
}) => {
  const ref = useRef(null);

  // Effect to trigger play/stop on visibility change
  useEffect(() => {
    if (!ref.current) return;
    if (visible) {
      ref.current.play();
    } else {
      ref.current.pause();
    }
  }, [visible]);

  return (
    <Lightbox
      visible={visible}
      onCloseIntent={onCloseIntent}
      {...props}
    >
      <Video
        ref={ref}
        src={src}
        css={css`
          max-width: 100%;
          // max-height: viewport height - top space for close button - bottom margin (equals to the top margin of the close button)
          max-height: calc(100vh - ${pixelsToRem(closeButtonSizeWithMargins + closeButtonMargin)});
          background: ${COLORS.darkest};
          object-fit: contain;
        `}
      />
    </Lightbox>
  );
};

export default VideoLightbox;
