import React from "react";
import PropTypes from "prop-types"
import { css } from "@emotion/core";
import { ReactComponent as FlourishSvgInline } from './assets/flourish.svg';

// Flourish is positioned absolutely so that it does not occupy space
// and shifts outside of its container. Ensure it's placed inside the
// proper block formatting context.

const Flourish = props => {
  return (
    <div {...props}  css={css`
      position: absolute;
      // the left angle is created flipping the right angle horizontally
      ${props.position === 'left' ? 'transform: scale(-1, 1);' : ''}
      bottom: 0;

      ::before {
        content: '';
        padding-top: ${172 / 348 * 100}%;
        width: 100%;
        display: block;
      }
    `}>
      <FlourishSvgInline role="presentation" css={css`
        fill: ${props.color};
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      `}/>
    </div>
  );
};

Flourish.propTypes = {
  color: PropTypes.string.isRequired,
  position: (props, propName) => {
    if (!/left|right/.test(props[propName]))
      return new Error(`Invalid position "${props[propName]}": must be "left" or "right"`);
  },
};

export default Flourish;
