import React from "react";

import Img from "gatsby-image";

const SmartImg = (
  props
) => (
  <Img
    {...props}
    placeholderStyle={{
      // The placeholder looks much nicer with the blur effect
      filter: `blur(20px)`,
      // If image has transparency, through it you can see the placeholder
      // suddenly disappearing in a ugly way: this transition solves it
      transition: `opacity 500ms ease 0s`,
    }}
  />
);

export default SmartImg;
