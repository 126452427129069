import React, { useRef, useState, useEffect } from "react";
import { css } from "@emotion/core";
import ResponsiveContainer from "./ResponsiveContainer";
import { pixelsToRem } from "../utils/css-helpers";
import { COLORS } from "../css-vars";

export const closeButtonSize = 40;
export const closeButtonMargin = 15;
export const closeButtonSizeWithMargins = closeButtonSize + closeButtonMargin * 2;

const Lightbox = ({
  visible,
  onCloseIntent,
  children,
  ...props
}) => {
  const childrenRef = useRef(null);
  const transitionDuration = 250;
  const [display, setDisplay] = useState(visible);
  const [opacity, setOpacity] = useState(visible ? 1 : 0);

  // Effect to capture clicks outside the video area
  useEffect(() => {
    // Don't capture clicks if the lightbox is hidden
    if (!visible) return;

    const onClick = (e) => {
      if (childrenRef.current && !childrenRef.current.contains(e.target)) {
        onCloseIntent();
      }
    };
    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("click", onClick);
    };
  }, [visible, childrenRef, onCloseIntent]);

  // Effect for changing CSS display before/after the opacity transition
  useEffect(() => {
    if (visible) {
      setDisplay(true);
      setTimeout(() => setOpacity(1), 50);
    } else {
      setOpacity(0);
      setTimeout(() => setDisplay(0), transitionDuration);
    }
  }, [visible]);

  return (
    // Using divs React triggers a "validateDOMNesting" every time you use this component in a non-block element;
    // being this very limiting we use only spans, that can be used almost everywhere
    <span
      {...props}
      css={css`
        display: ${display ? `block` : `none`};
        opacity: ${opacity};
        transition: opacity ${transitionDuration}ms ease-in-out;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        // 100vh doesn't cover the entire height on some iPhone X webviews;
        // increasing to 120 fixes the glitch with no downsides
        height: 120vh;
        background: rgba(0, 0, 0, .8);
        z-index: 99999;
      `}
    >
      <ResponsiveContainer tag="span" spacingTop="none" spacingBottom="none" css={css`display: table; height: 100vh;`}>
        <span css={css`display: table-cell; text-align: center; vertical-align: middle`}>
          <span css={css`
            display: inline-block;
            position: relative;
            padding-top: ${pixelsToRem(closeButtonSizeWithMargins)};
          `}>
            <button
              aria-label="Close"
              css={css`
                position: absolute;
                right: 0;
                top: ${pixelsToRem(closeButtonMargin)};
                color: ${COLORS.lightest};
                border: solid ${pixelsToRem(2)} currentColor;
                width: ${pixelsToRem(closeButtonSize)};
                height: ${pixelsToRem(closeButtonSize)};
                border-radius: ${pixelsToRem(closeButtonSize)};;
                font: 1.1em/${pixelsToRem(38)} sans-serif;
                cursor: pointer;
                opacity: .6;
                transition: opacity 250ms ease-in-out;
                :hover, :focus {
                  opacity: 1;
                }
              `}
            >
              <span aria-hidden="true">✕</span>
            </button>
            <span ref={childrenRef}>
              {children}
            </span>
          </span>
        </span>
      </ResponsiveContainer>
    </span>
  );
};

export default Lightbox;
