import React from "react";
import { css } from "@emotion/core";
import Flourish from "./Flourish";
import PhotoOverlay from "./PhotoOverlay";

import { BREAKPOINTS } from "../../css-vars";
import { mediaQuery, pixelsToRem } from "../../utils/css-helpers";
import SmartImg from "../SmartImg";

// WHY: Values taken from a template for the overlap where the card image is 10% of the width of the image
//      larger on all sides.
const imageWidth = 500;
const imageGutterWidth = 75;
const imageAndGutterWidth = imageWidth + (imageGutterWidth * 2);
const imageFlourishOffset = 50;
const imageFlourishWidth = 280;

const PhotoWithFlourish = ({
  photoFluid = {},
  overlayFluid,
  loading,
  flourishColor,
  flourishPosition,
  ...props
}) => {
  return (
    <figure
      {...props}

      css={css`
        overflow: hidden;
        position: relative;

        ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
          max-width: ${pixelsToRem(420)};
        }
      `}
    >
      {photoFluid &&
        <SmartImg
          fluid={photoFluid}
          loading={loading}
          alt=""
          css={css`
            margin: ${Number.parseFloat(imageGutterWidth / imageAndGutterWidth * 100).toFixed(4)}% auto;
            width: ${Number.parseFloat(imageWidth / imageAndGutterWidth * 100).toFixed(4)}%;
          `}
        />
      }

      {flourishColor &&
        <Flourish
          color={flourishColor}
          position={flourishPosition}
          style={{
            /* WHY: This is done with inline styles rather than CSS Custom Properties to enable IE support */
            bottom: `${imageFlourishOffset / imageAndGutterWidth * 100 * photoFluid.aspectRatio}%`,
          }}
          css={css`
            ${flourishPosition}: ${imageFlourishOffset / imageAndGutterWidth * 100}%;
            width: ${imageFlourishWidth / imageAndGutterWidth * 100}%;
          `}
        />
      }

      {overlayFluid && <PhotoOverlay fluid={overlayFluid} loading={loading} />}
    </figure>
  );
};

export default PhotoWithFlourish;
