import React from "react";
import { css } from "@emotion/core";
import { pixelsToRem, mediaQuery } from "./../utils/css-helpers";
import { BREAKPOINTS } from "../css-vars";

import Paragraph from "./Paragraph";
import Heading from "./Heading";

const Icon = ({
  iconUrl,
  heading,
  body,
  ...props
}) => {

  return (
    <div {...props} css={css`
      flex: 0 0 auto;
      text-align: center;
      margin: ${pixelsToRem(20)};
      max-width: ${pixelsToRem(160)};
      width: 100%;

      ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
        max-width: ${pixelsToRem(200)};
      }
    `}>
      <img
        src={iconUrl}
        alt=""
        css={css`
          object-fit: contain;
          width: ${pixelsToRem(130)};
          height: ${pixelsToRem(130)};
          ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
            width: ${pixelsToRem(160)};
            height: ${pixelsToRem(160)};
          }
        `}
        role="presentation"
      />
      <Heading tag="h3" level="h5" spacingBottom="none" css={css`
        text-align: center;
      `}>
        {heading}
      </Heading>
      <Paragraph
        level="small"
        spacingTop="half"
        spacingBottom="none"
        align="center"
      >
        {body}
      </Paragraph>
    </div>
  )
};

export default Icon
