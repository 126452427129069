import React from "react";

const Video = React.forwardRef(({
    src,
    ...props
  },
  ref,
) => (
  // At the time of writing there's no policy for mandatory subtitles in
  // videos, so we disable the related accessibility check
  /* eslint-disable jsx-a11y/media-has-caption */
  <video
    controls
    {...props}
    ref={ref}
  >
    <source src={src} />

    Sorry, your browser doesn't support embedded videos.
    <br/><a href={src}>Download the video</a>
  </video>
));

export default Video;
