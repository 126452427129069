import React from "react";

import Heading from "./Heading";
import SmartImg from "./SmartImg";

const TeamMember = ({
  photoFluid,
  name,
  jobTitle,
  shortBio,
  ...props
}) => {
  return (
    <figure {...props}>
      <SmartImg fluid={photoFluid}/>
      <Heading tag="h3" level="h4" spacingBottom="none">
        {name}
      </Heading>
      <Heading spacingTop="half" level="h5" tag="p">
        {jobTitle}
      </Heading>
      {shortBio}
    </figure>
  );
};

export default TeamMember;
