import React from "react";
import { css } from "@emotion/core";
import Img from "gatsby-image";

const PhotoOverlay = ({
  fluid,
  ...props
}) => {

  return (
    <Img
      {...props}
      css={css`
        display: block;
        // Gatsby puts position:relative as an inline style,
        // there's no other way to win over it...
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
          // at the time of writing there is no cleaner way to
          // tell Gatsby to use "contain" instead of "cover"...
          object-fit: contain !important;
        }
      `}
      fluid={fluid}
      alt=""
      role="presentation"
    />
  );
};

export default PhotoOverlay;
