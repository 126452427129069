// NOTE: This layout has not been tested in IE11 as it was not used at the time of production

import React from 'react'
import { css } from "@emotion/core";
import { mediaQuery } from "../utils/css-helpers";
import { BREAKPOINTS } from "../css-vars";
import ResponsiveContainer from "./ResponsiveContainer";
import Heading from "./Heading";
import Paragraph from "./Paragraph";
import CallToAction from "./CallToAction";

const Copy3Columns = ({
  left,
  center,
  right,
  ...props
}) => {

  const Column = ({
    heading,
    body,
    ctaLabel,
    ctaLink,
  }) => (
    <section>
      <Heading level="h3">{heading}</Heading>
      <Paragraph>{body}</Paragraph>
      {ctaLabel && ctaLink &&
      <CallToAction
        anchorClassName='pageblockcta' // Class to make the link identifiable in Google Tag Manager
        spacingBottom="none"
        link={ctaLink}
        caption={ctaLabel}
      />
      }
    </section>
  );

  return (
    <section {...props}>
      <ResponsiveContainer css={css`
        ${mediaQuery({minWidth: BREAKPOINTS.medium})} {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 4%;
        }
      `}>
        <Column {...left} />
        <Column {...center} />
        <Column {...right} />
      </ResponsiveContainer>
    </section>
  );
};

export default Copy3Columns;
