import React from "react";
import { css } from "@emotion/core";

import { mediaQuery, pixelsToRem, createSpacingUnitMediaQueries } from "../utils/css-helpers";
import { BREAKPOINTS } from "../css-vars";

import Copy from "./Copy";

const SmallCopy = ({
  imgUrl,
  content,
  buttonText,
  buttonLink,
  ...props
}) => (
  <section {...props} css={css`
    // WHY: When full-width the content is unbalanced below BREAKPOINTS.small, 520 matches the maximum
    //      size it reaches at BREAKPOINTS.large when spread across 2 columns
    max-width: ${pixelsToRem(520)};

    > :last-child {
      margin-bottom: 0;
    }
  `}>

    {imgUrl &&
      <div css={css`
        ${createSpacingUnitMediaQueries({
          'margin-top': `calc(SPACING_UNIT / 2)`,
          'margin-bottom': `calc(SPACING_UNIT / 8)`,
        })}

        width: 100%;

        // This wrapper is a CSS hack that allows to scale the image using just
        // a percentage of its own width, maintaining aspect ratio, but it is unsupported
        // in IE11 so needs to be handled in a @supports
        @supports (width: max-content) {
          width: max-content;
          max-width: 100%;
          display: flex;
        }
      `}>
        <img
          src={imgUrl}
          alt=""
          role="presentation"
          css={css`
            max-width: 85%;
            width: auto;

            ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
              max-width: 100%;
            }
          `}
        />
      </div>
    }

    <Copy
      ctaLabel={buttonText}
      ctaLink={buttonLink}
      ctaSpacingBottom="half"
      ctaLevel="small"
      copy={content}
    />
  </section>
);

export default SmallCopy;
