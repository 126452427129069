import React from "react";
import { css } from "@emotion/core";
import IconGrid from "./IconGrid";
import Icon from "./Icon";
import ContentfulRichText from "./ContentfulRichText";

const ContentfulIconGrid = ({
  contentfulData: {
    headingStrapline,
    heading,
    content,
    icons
  },
  ...props
}) => (
  <IconGrid
    {...props}
    strapline={headingStrapline}
    heading={heading}
    content={content &&
      <ContentfulRichText
        json={content.json}
        css={css`
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        `}
      />
    }
  >
    {icons.map(({
      id,
      icon: {
        localFile: {
          publicURL
        }
      },
      heading,
      body
    }) =>
      <Icon
        key={id}
        iconUrl={publicURL}
        heading={heading}
        body={body}
      />
    )}
  </IconGrid>
);

export default ContentfulIconGrid;
