import React from "react";
import MidBackground from "./MidBackground";
import ContentfulCopy from "./ContentfulCopy";
import ContentfulCopyWithCards from "./ContentfulCopyWithCards";
import ContentfulCopyWithPhoto from "./ContentfulCopyWithPhoto";
import ContentfulTeam from "./ContentfulTeam";
import ContentfulSmallCopyGrid from "./ContentfulSmallCopyGrid";
import ContentfulCopy3Columns from "./ContentfulCopy3Columns";
import ContentfulIconGrid from "./ContentfulIconGrid";
import ContentfulLogoStrip from "./ContentfulLogoStrip";

const ContentfulPageBlocks = ({
  blocks,
  backgrounds,
}) => {

  let renderedBlocks = renderBlocks(blocks);

  renderedBlocks = addBackgrounds(backgrounds, renderedBlocks);

  return renderedBlocks;
};

export default ContentfulPageBlocks;


const renderBlocks = (blocks) => {
  // Map Contentful typenames to React components
  // React components have the exact same names of their Contentful
  // counterpart, so the mapping comes very easy
  const COMPONENT_MAP = {
    ContentfulCopy,
    ContentfulCopyWithCards,
    ContentfulCopyWithPhoto,
    ContentfulTeam,
    ContentfulSmallCopyGrid,
    ContentfulCopy3Columns,
    ContentfulIconGrid,
    ContentfulLogoStrip,
  };

  // Convert all the Contentful blocks to React components
  if (blocks === null) blocks = [];
  let renderedBlocks = blocks.map(block => {
    const Component = COMPONENT_MAP[block.__typename];
    if (!Component) {
      console.error(`Skipping unknown page block typename: ${block.__typename}`);
      return null;
    }
    return <Component contentfulData={block} key={block.id}/>;
  });

  // WHY: Add the id to the first section
  if (renderedBlocks[0]) {
    renderedBlocks[0] = React.cloneElement(renderedBlocks[0], { id: 'content-start' });
  }

  return renderedBlocks;
};


const addBackgrounds = (backgrounds, renderedBlocks) => {
  if (!backgrounds) return renderedBlocks;

  // For each background add a <MidBackground> wrapper around its children
  for (const background of backgrounds) {
    const {
      id,
      entryTitle,
      topStyle, bottomStyle,
    } = background;

    let { fromBlock, toBlock } = background;
    // In Contentful fromBlock and toBlock are one-based indexes (first element = 1)
    // but in this algorithm it's better to have them zero-based.
    fromBlock--;
    toBlock--;

    if (fromBlock >= renderedBlocks.length) {
      console.error(`Background "${entryTitle}" skipped: fromBlock is ${fromBlock} but page has ${renderedBlocks.length} blocks`);
      continue;
    }
    if (toBlock >= renderedBlocks.length) {
      console.error(`Background "${entryTitle}" skipped: toBlock is ${toBlock} but page has ${renderedBlocks.length} blocks`);
      continue;
    }

    // Pick all the blocks covered by this background
    const blocksInBackground = [];
    for (let i = fromBlock; i <= toBlock; i++) {
      // During background processing some indexes will become null, other
      // will become instances of <MidBackground>: in both cases it means
      // that the block at that index is under another background
      if (renderedBlocks[i] === null || renderedBlocks[i].type === MidBackground) {
        console.error(`Background "${entryTitle}" overlaps with another background; check properties fromBlock / toBlock`);
        continue;
      } else {
        blocksInBackground.push(renderedBlocks[i]);
        // Flag this block as already covered (it's important to keep blocks indexes unaltered)
        renderedBlocks[i] = null;
      }
    }

    if (blocksInBackground.length) {
      const prevBlock = renderedBlocks[fromBlock - 1];
      const nextBlock = renderedBlocks[toBlock + 1];
      // Underlap ONLY if the block before/after the background is a CopyWithPhoto
      // (with the other components the underlap can create visual imbalances)
      const topUnderlap = prevBlock !== undefined && prevBlock.type === ContentfulCopyWithPhoto;
      const bottomUnderlap = nextBlock !== undefined && nextBlock.type === ContentfulCopyWithPhoto;
      // Put the <MidBackground> at the index of its first block
      // (all other indexes of its children will remain null)
      renderedBlocks[fromBlock] = (
        <MidBackground
          key={id}
          topStyle={topStyle}
          bottomStyle={bottomStyle}
          topUnderlap={topUnderlap}
          bottomUnderlap={bottomUnderlap}
        >
          {blocksInBackground}
        </MidBackground>
      );
    }
  }

  // Remove all null indexes left behind by backgrounds processing,
  // we don't need them anymore
  renderedBlocks = renderedBlocks.filter(b => b !== null);

  return renderedBlocks;
};
