import React from 'react'
import { css } from "@emotion/core";

import { mediaQuery, pixelsToRem, createAutoPopulatedGrid } from "../utils/css-helpers";
import { BREAKPOINTS, BREAKPOINT_SPACING } from "../css-vars";

import ResponsiveContainer from "./ResponsiveContainer";
import Copy from "./Copy";

const SmallCopyGrid = ({
  strapline,
  heading,
  content,
  buttonText,
  buttonLink,
  children,
  ...props
}) => (
  <section {...props}>
    <ResponsiveContainer>

      <Copy
        strapline={strapline}
        heading={heading}
        ctaLabel={buttonText}
        ctaLink={buttonLink}
        copy={content}
      />

      <div css={css`
        ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
          ${createAutoPopulatedGrid(children.length, {
            columns: 2,
            rowGap: pixelsToRem(BREAKPOINT_SPACING.small / 8),
          })};
        }

        // WHY: createAutoPopulatedGrid doesn't play nicely with createSpacingUnitMediaQueries so we have to
        //      manually declare the spacing for the rows as if it did
        > :nth-of-type(n) {
          ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
            margin-bottom: ${pixelsToRem(BREAKPOINT_SPACING.tiny / 8)};
          }

          ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
            margin-bottom: ${pixelsToRem(BREAKPOINT_SPACING.medium / 8)};
          }

          ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
            margin-bottom: ${pixelsToRem(BREAKPOINT_SPACING.large / 8)};
          }
        }

      `}>
        {children}
      </div>

    </ResponsiveContainer>
  </section>
);

export default SmallCopyGrid;
