import React from 'react';
import ContentfulRichText from "./ContentfulRichText";
import SmallCopyGrid from "./SmallCopyGrid";
import SmallCopy from "./SmallCopy";

const ContentfulSmallCopyGrid = ({
  contentfulData: {
    headingStrapline,
    heading,
    content,
    buttonText,
    buttonLink,
    smallCopyBlocks,
  },
  ...props
}) => (
  <SmallCopyGrid
    {...props}
    strapline={headingStrapline}
    heading={heading}
    content={content && <ContentfulRichText json={content.json}/>}
    buttonText={buttonText}
    buttonLink={buttonLink}
  >
    {smallCopyBlocks && smallCopyBlocks.map(({
        id,
        logo,
        content,
        buttonText,
        buttonLink,
      }) => {
        const imgUrl = logo ? logo.localFile.publicURL : null;
        return (
          <SmallCopy
            key={id}
            imgUrl={imgUrl}
            content={content && <ContentfulRichText json={content.json}/>}
            buttonText={buttonText}
            buttonLink={buttonLink}
          />
        );
      }
    )}
  </SmallCopyGrid>
);


export default ContentfulSmallCopyGrid;
