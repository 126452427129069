import React from "react";
import { css } from "@emotion/core";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { COLORS } from "../css-vars";
import List from "./List";
import Heading from "./Heading";
import Paragraph from "./Paragraph";
import SmartLink from "./SmartLink";
import ContentfulRichTextAsset from "./ContentfulRichTextAsset";
import ContentfulRichTextAssetLink from "./ContentfulRichTextAssetLink";

const ContentfulRichText = ({
  json,
  blogStyle = false,
  ...props
}) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
    },
    renderText: text => {
      // Remove trailing/leading spaces, including newlines
      // (but keep at least one space to separate inline tags)
      text = text.replace(/^\s+|\s+$/g, ' ');
      // Replaces \n from Contentful in <br/>
      // Snippet found on the official documentation of the rich text renderer
      // https://www.npmjs.com/package/@contentful/rich-text-react-renderer
      text = text.split('\n').reduce((acc, textLine, index) => {
        // After the first element add a <br/> before each new textLine
        if (index > 0) acc.push(<br key={`br-${index}`} />);
        acc.push(textLine);
        return acc;
      }, []);
      if (text.length === 1) return text[0];
      return text;
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET] : node =>
        <ContentfulRichTextAsset node={node}/>,
      [BLOCKS.HEADING_2]: (node, children) =>
        <Heading tag="h2" {...props}>{children}</Heading>,
      [BLOCKS.HEADING_3]: (node, children) =>
        <Heading tag="h3" level="h5" css={css`color: ${COLORS.primaryA11y}`} {...props}>{children}</Heading>,
      [BLOCKS.UL_LIST]: (node, children) =>
        <List blogStyle={blogStyle} {...props}>{children}</List>,
      [BLOCKS.OL_LIST]: (node, children) =>
        <List ordered={true} blogStyle={blogStyle} {...props}>{children}</List>,
      [BLOCKS.LIST_ITEM]: (node, children ) =>
        <li>
          {children.map(child => {
            // WHY: This removes elements from within `<p>` tags inside the `<li>`s
            if (child.type === Paragraph || child.type === `p`) {
              return child.props.children;
            }
            return child;
          })}
        </li>,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        // Filter out empty strings
        children = children.filter(child => typeof child !== 'string' || child.trim() !== '');
        if (children.length > 0) {
          return <Paragraph level={blogStyle ? `medium` : `regular`} {...props}>{children}</Paragraph>;
        }
        return null;
      },
      [INLINES.HYPERLINK]: (node, children) =>
        <SmartLink link={node.data.uri}>{children}</SmartLink>,
      [INLINES.ASSET_HYPERLINK]: (node, children) =>
        <ContentfulRichTextAssetLink node={node}>{children}</ContentfulRichTextAssetLink>
    },
  };

  return documentToReactComponents(json, options);
};

export default ContentfulRichText;
