import React from 'react'
import { css } from "@emotion/core";

import Heading from "../Heading";

import { mediaQuery, pixelsToRem, createSpacingUnitMediaQueries } from "../../utils/css-helpers";
import { BREAKPOINTS, COLORS } from "../../css-vars";

import ScheduleIconSvg from "./assets/schedule.svg";
import MoneyIconSvg from "./assets/money.svg";
import EmployeesIconSvg from "./assets/employees.svg";
import ServicesIconSvg from "./assets/services.svg";
import LendersIconSvg from "./assets/lenders.svg";

const CopyWithCards = ({
  iconId,
  title,
  content,
  ...props
}) => {

  const ICON_MAP = {
    schedule: ScheduleIconSvg,
    money: MoneyIconSvg,
    employees: EmployeesIconSvg,
    services: ServicesIconSvg,
    lenders: LendersIconSvg,
  };

  const icon = ICON_MAP[iconId];

  return <aside {...props} css={css`
    ${createSpacingUnitMediaQueries({
      'padding': `calc(SPACING_UNIT / 4)`,
    })}

    background: ${COLORS.lightest};
    margin: 0 auto ${pixelsToRem(25)};
    max-width: ${pixelsToRem(300)};

    ${mediaQuery({minWidth: BREAKPOINTS.small})} {
      max-width: ${pixelsToRem(400)};
    }
  `}>
    {icon && <img src={icon} alt="" role="presentation" css={css`
      margin-top: ${pixelsToRem(10)};
    `}/>}
    {title && <Heading tag="h3" level="h2" spacingBottom="none" spacingTop={icon ? `half` : `none`} css={css`
      color: ${COLORS.primaryA11yLarge};
    `}>{title}</Heading>}
    <Heading level="h5" tag="p" spacingTop="half" spacingBottom="none">{content}</Heading>
  </aside>
};

export default CopyWithCards;
