import React from 'react'
import ContentfulRichText from "./ContentfulRichText";
import Copy3Columns from "./Copy3Columns";

const ContentfulCopy3Columns = ({
  contentfulData: {
    leftHeading,
    leftBody: { json: leftBodyJson },
    leftCtaLabel,
    leftCtaLink,
    centerHeading,
    centerBody: { json: centerBodyJson },
    centerCtaLabel,
    centerCtaLink,
    rightHeading,
    rightBody: { json: rightBodyJson },
    rightCtaLabel,
    rightCtaLink,
  },
  ...props
}) => {

  return <Copy3Columns
    {...props}
    left={{
      heading: leftHeading,
      body: <ContentfulRichText json={leftBodyJson}/>,
      ctaLabel: leftCtaLabel,
      ctaLink: leftCtaLink,
    }}
    center={{
      heading: centerHeading,
      body: <ContentfulRichText json={centerBodyJson}/>,
      ctaLabel: centerCtaLabel,
      ctaLink: centerCtaLink,
    }}
    right={{
      heading: rightHeading,
      body: <ContentfulRichText json={rightBodyJson}/>,
      ctaLabel: rightCtaLabel,
      ctaLink: rightCtaLink,
    }}
  />;
};

export default ContentfulCopy3Columns;
