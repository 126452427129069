import React from 'react'
import { css } from "@emotion/core"

import { pixelsToRem, createSpacingUnitMediaQueries } from "../utils/css-helpers";

import CallToAction from "./CallToAction";
import Copy from "./Copy";
import ResponsiveContainer from "./ResponsiveContainer";

const LogoStrip = ({
  strapline,
  heading,
  content,
  ctaLabel,
  ctaLink,
  children,
  ...props
}) => {

  return (
    <section {...props}>
      <ResponsiveContainer css={css`text-align: center`}>
        <Copy
          strapline={strapline}
          heading={heading}
          copy={content}
          align="center"
        />
        <div {...props} css={css`
          ${createSpacingUnitMediaQueries({
            margin: `calc(SPACING_UNIT / 4) 0`,
          })}

          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          img {
            ${createSpacingUnitMediaQueries({
              margin: `calc(SPACING_UNIT / 4) calc(SPACING_UNIT / 2)`,
            }, {
              includeMin: {
                tiny: `height: ${pixelsToRem(60)}`,
                medium: `height: ${pixelsToRem(75)}`,
              },
            })}
            // Logos will all have the same height, so eventually scaling
            // has to be embedded in the image

            flex: 0 0 auto;
            width: auto;
          }
        `}>
          {children}
        </div>
        {ctaLabel && ctaLink &&
          <CallToAction
            anchorClassName='pageblockcta' // Class to make the link identifiable in Google Tag Manager
            align="center"
            spacingBottom="none"
            link={ctaLink}
            caption={ctaLabel}
          />
        }
      </ResponsiveContainer>
    </section>
  );
};

export default LogoStrip;
