import React from "react";
import { css } from "@emotion/core";

import { mediaQuery, pixelsToRem, createSpacingUnitMediaQueries, createAutoPopulatedGrid } from "../utils/css-helpers";
import { BREAKPOINTS, BREAKPOINT_SPACING } from "../css-vars";

import ResponsiveContainer from "./ResponsiveContainer";
import Heading from "./Heading";

const Team = ({
  headingStrapline = `Leadership`,
  heading = `Meet the Team`,
  children,
  ...props
}) => (
  <section {...props}>
    <ResponsiveContainer>
      <Heading
        align="right"
        strapline={headingStrapline}
      >
        {heading}
      </Heading>
      <div css={css`
        ${createSpacingUnitMediaQueries({
          'margin-top': 'calc(SPACING_UNIT / 2)',
        })}

        ${mediaQuery({ minWidth: BREAKPOINTS.tiny, maxWidth: BREAKPOINTS.medium })} {
          ${createAutoPopulatedGrid(children.length, {
            columns: 2,
            columnGap: `7%`,
            rowGap: pixelsToRem(BREAKPOINT_SPACING.small / 2),
          })};
        }

        ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
          ${createAutoPopulatedGrid(children.length, {
            columns: 3,
            columnGap: `7%`,
            rowGap: pixelsToRem(BREAKPOINT_SPACING.medium / 2),
          })};
        }

        ${mediaQuery({ maxWidth: BREAKPOINTS.tiny })} {
          > :nth-of-type(n) {
            margin-bottom: ${pixelsToRem(BREAKPOINT_SPACING.tiny / 2)};
          }
        }
      `}>
        {children}
      </div>
    </ResponsiveContainer>
  </section>
);

export default Team;
