import React from "react";
import { css } from "@emotion/core";

import { pixelsToRem } from "./../../utils/css-helpers.js";

import Paragraph from "./../Paragraph";

import CheckSvg from "./assets/check.svg";

const List = ({
  children,
  ordered = false,
  blogStyle = false,
  ...props
}) => {
  const tag = ordered ? `ol` : `ul`;

  // WHY: Values are in ems to be
  const listSpacing = 3;
  const itemSpacing = 2;
  const regularCss = css`
    display: grid;
    line-height: 1.6em;
    grid-template-columns: repeat(var(--list-column-count, 1), 1fr);
    grid-gap: 0 ${pixelsToRem(20 * 2)};
    // TODO: do we need it? it breaks the layout when there is only 1 column
    //max-width: calc(36em * var(--list-column-count, 1));
    margin-top: calc(${listSpacing}em * var(--spacing-top, 1));
    margin-bottom: calc(${listSpacing - itemSpacing}em);
    margin-bottom: calc(${listSpacing - itemSpacing}em * var(--spacing-bottom, 1));

    li {
      margin-bottom: calc(${itemSpacing}em);
      margin-bottom: calc(${itemSpacing}em * var(--spacing-bottom, 1));
      font-weight: 500;
      padding-left: ${pixelsToRem(20 + 10)};
      position: relative;

      ::before {
        background: url(${CheckSvg});
        background-repeat: no-repeat;
        content: '';
        display: block;
        position: absolute;
        top: calc(0.75em - ${pixelsToRem(20 / 2)});
        left: 0;
        height: ${pixelsToRem(20)};
        width: ${pixelsToRem(20)};
      }
    }
  `;

  const blogCss = css`
    margin-left: 1.1em;
    li {
      list-style: ${ordered ? `decimal` : `disc`};
      padding-left: .6em;
    }
  `;

  return (
    <Paragraph
      tag={tag}
      level={blogStyle ? `medium` : `small`}
      css={blogStyle ? blogCss : regularCss}
      {...props}
    >
      {children}
    </Paragraph>
  )
};

export default List;
