import React, { useState } from "react";
import SmartLink from "./SmartLink";
import VideoLightbox from "./VideoLightbox";

const VideoLink = ({
  link,
  children,
  ...props
}) => {
  const [lightboxVisible, setLightboxVisible] = useState(false);
  return (
    <>
      <SmartLink
        link={link}
        onClick={(e) => { e.preventDefault(); setLightboxVisible(true); }}
        {...props}
      >
        {children}
      </SmartLink>
      <VideoLightbox
        src={link}
        visible={lightboxVisible}
        onCloseIntent={() => { setLightboxVisible(false) }}
      />
    </>
  )
};

const ContentfulRichTextAssetLink = ({
  node,
  children,
  ...props
}) => {
  if (!node.data.target.fields) {
    // Detecting known bug and offering workaround (😩)
    console.error(`Rich Text error: node data missing, try running "gatsby clean" and then rebuild the site`);
    return null;
  }

  const { contentType, url } = node.data.target.fields.file[`en-US`];
  if (contentType.startsWith(`video`)) {
    return <VideoLink link={url} {...props}>{children}</VideoLink>;
  } else {
    return <SmartLink link={url} {...props}>{children}</SmartLink>;
  }
};

export default ContentfulRichTextAssetLink;
