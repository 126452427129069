import React from "react";
import Team from "./Team";
import TeamMember from "./TeamMember";
import ContentfulRichText from "./ContentfulRichText";

const ContentfulTeam = ({
  contentfulData: {
    headingStrapline,
    heading,
    teamMembers,
  },
  ...props
}) => (
  <Team
    {...props}
    heading={heading}
    headingStrapline={headingStrapline}
  >
    {teamMembers.map(({
        id,
        photo,
        name,
        jobTitle,
        shortBio,
      }) =>
        <TeamMember
          key={id}
          photoFluid={photo.localFile.childImageSharp.fluid}
          name={name}
          jobTitle={jobTitle}
          shortBio={<ContentfulRichText json={shortBio.json}/>}
        />
    )}
  </Team>
);

export default ContentfulTeam;
