import React from "react";
import { css } from "@emotion/core";

import { mediaQuery, createSpacingUnitMediaQueries } from "../utils/css-helpers";
import { BREAKPOINTS, COLORS } from "../css-vars";

import Video from "./Video";

const landscapeCss = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  ${createSpacingUnitMediaQueries({
    'margin-bottom': `calc(SPACING_UNIT / 2)`,
    'margin-top': `calc(SPACING_UNIT / 2)`,
  }, {
    includeMin: {
      small: `max-width: 80%;`,
    },
  })}
`;

const portraitCss = css`
  display: block;

  ${createSpacingUnitMediaQueries({
    'margin': `calc(SPACING_UNIT / 4)`,
  })}

  ${createSpacingUnitMediaQueries({
    'max-width': `calc(50% - calc(SPACING_UNIT / 8))`,
  }, {
    excludeBreakpoints: [`tiny`],
    includeMin: {
      small: `float: left; margin-left: 0; margin-top: 0;`,
    },
  })}

  ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
  }
`;

const ContentfulRichTextImage = ({
  node,
  ...props
}) => {
  const title = node.data.target.fields.title[`en-US`];
  const file = node.data.target.fields.file[`en-US`];
  const url = file.url;
  const aspectRatio = file.details.image.width / file.details.image.height;
  const landscape = aspectRatio > 1;
  // TODO: add support for responsive images
  // It's very difficult to do it right now because the gatsby-source-contentful
  // does not give you "fluid" fields in the rich text data structure
  return (
    <img
      src={url}
      alt={title}
      css={landscape ? landscapeCss : portraitCss}
      {...props}
    />
  );
};


const ContentfulRichTextVideo = ({
  node,
  ...props
}) => {
  const { url } = node.data.target.fields.file[`en-US`];
  return <Video src={url} {...props} css={css`
    ${landscapeCss}

    // Add background for the video element on mobile, since Contentful doesn't offer a thumbnail
    // and the first frame is not preloaded on mobile, so nothing is showed until you press play
    background: ${COLORS.darkest};
  `} />;
};


const ContentfulRichTextAsset = ({
  node,
  ...props
}) => {
  if (!node.data.target.fields) {
    // Detecting known bug and offering workaround (😩)
    console.error(`Rich Text error: node data missing, try running "gatsby clean" and then rebuild the site`);
    return null;
  }

  const contentType = node.data.target.fields.file[`en-US`].contentType;
  if (contentType.startsWith(`image`)) {
    return <ContentfulRichTextImage node={node} {...props} />;
  } else if (contentType.startsWith(`video`)) {
    return <ContentfulRichTextVideo node={node} {...props} />;
  } else {
    console.error(`Rich Text error: asset with title "${node.data.target.fields.title[`en-US`]}" not rendered in rich text: unsupported content type "${contentType}" (currently supporting only images and videos)`);
    return null;
  }
};

export default ContentfulRichTextAsset;
